.header {
  .nav-link, .nav-tabs a {
    transition: background-color .3s, color .3s;

    img {
      opacity: 0;
      position: absolute;
      transition: right .5s, top .5s, opacity .3s;
    }

    &:hover:not(.active) {
      @extend .rounded;

      img {
        opacity: 0.5;
      }
    }

    &:hover:not(.active), &:focus:not(.active) {
      background-color: $dark !important;
      color: #d5d5d5 !important;
    }

    &.active {
      @extend .bg-warning, .text-dark-grey, .rounded;

      img {
        opacity: 0.5;
      }
    }
  }

  .nav-item {
    margin-bottom: 0;
  }

  .nav-top-tabs .nav-item {
    &:first-of-type a {
      &:first-of-type {
        @include bgAnimation(
                $init1Y: 80%, $hover1X: 88%, $hover1Y: 15%, $active1X: 32%, $active1Y: 45%, $type1: 1, $width1: 20,
                $init2Y: calc(80% - 18px), $hover2X: 52%, $hover2Y: 40%, $active2X: 28%, $active2Y: 38%, $type2: 0
        )
      }
    }

    &:nth-of-type(2) a {
      &:first-of-type {
        @include bgAnimation(
                $init1Y: 30%, $hover1X: 58%, $hover1Y: 55%, $active1X: 80%, $active1Y: 43%, $type1: 1, $width1: 10,
                $init2Y: calc(40% + 18px), $hover2X: 52%, $hover2Y: 40%, $active2X: calc(20% - 8px), $active2Y: calc(43% + 6px), $type2: 0,
                $init3Y: 60%, $hover3X: 22%, $hover3Y: 20%, $active3X: 78%, $active3Y: calc(38% - 10px), $type3: 0
        )
      }
    }

    &:nth-of-type(3) a {
      &:first-of-type {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: 28%, $hover1Y: 25%, $active1X: 80%, $active1Y: 43%, $type1: 0, $width1: 10,
                $init2Y: calc(60% + 18px), $hover2X: 32%, $hover2Y: 67%, $active2X: calc(20% - 8px), $active2Y: calc(43% + 6px), $type2: 1
        )
      }
    }
  }

  .nav-bottom-tabs .nav-item {
    &:first-of-type a {
      &:first-of-type {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: calc(12% - 6px), $hover1Y: calc(60% + 28px), $active1X: calc(8% + 26px), $active1Y: 20%, $type1: 1, $width1: 10,
                $init2Y: calc(40% + 18px), $hover2X: 12%, $hover2Y: 60%, $active2X: 8%, $active2Y: calc(20% + 6px), $type2: 1,
                $init3Y: 60%, $hover3X: 22%, $hover3Y: 30%, $active3X: 28%, $active3Y: calc(38% - 10px), $type3: 0,
                $init4Y: calc(60% + 18px), $hover4X: calc(22% - 26px), $hover4Y: 32%, $active4X: calc(28% + 12px), $active4Y: 38%, $type4: 0
        )
      }
    }

    &:nth-of-type(2) a {
      &:first-of-type {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: 88%, $hover1Y: 15%, $active1X: calc(28% - 30px), $active1Y: 45%, $type1: 1, $width1: 50,
                $init2Y: calc(40% + 18px), $active2X: 28%, $active2Y: 38%, $type2: 1,
                $init3Y: calc(40% + 18px), $hover3X: 52%, $hover3Y: calc(40% - 9px), $active3X: calc(28% - 9px), $active3Y: calc(38% - 9px), $type3: 0,
                $init4Y: calc(40% + 18px), $hover4X: 52%, $hover4Y: 40%, $active4X: 28%, $active4Y: 38%, $type4: 0
        )
      }
    }
  }
}
