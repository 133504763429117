.logobar {
  > .brand {
    transition: background-color .3s;

    @include media-breakpoint-down(sm) {
      flex-basis: 50%;
      max-width: 50%;
      margin: 0 !important;
      border-radius: 0 !important;

      &:first-of-type {
        border-top-left-radius: $border-radius !important;
      }
      &:nth-of-type(2) {
        border-top-right-radius: $border-radius !important;
      }
      &:nth-last-of-type(3) {
        border-bottom-left-radius: $border-radius !important;
      }
      &:nth-last-of-type(2) {
        border-bottom-right-radius: $border-radius !important;
      }
    }
  }

  img {
    filter: grayscale(1) contrast(2);
    opacity: 0.4;
    transition: filter .3s, opacity .3s;
  }

  .img-light {
    filter: invert(1) grayscale(1);
  }

  > .brand:hover {
    background-color: #fff !important;

    img {
      filter: none;
      opacity: .9;
    }
  }
}
