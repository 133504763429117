.grid-small-text-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1.4fr 1fr 1.4fr 1fr;
  gap: 1px 1px;
  grid-template-areas: ". ." "span-2-2 span-2-2" ". ." "span-2-1 span-2-1" ". .";

  .span-2-1 {
    grid-area: span-2-1;
  }

  .span-2-2 {
    grid-area: span-2-2;
  }

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1.1fr 1.1fr 1.1fr 0.7fr;
    grid-template-rows: 1fr 2fr 1fr;
    gap: 1px 1px;
    grid-template-areas: ". . . ." "span-2-1 span-2-1 span-2-2 span-2-2" ". . . .";
  }
}
