$transition-duration: 0.7s;

.accordion-how-it-works {
  > [class*=col] {
    height: rem-calc(260px);

    @include media-breakpoint-up(sm) {
      height: rem-calc(380px);
    }
  }

  .accordion-item {
    transition: flex $transition-duration, background-color $transition-duration, color $transition-duration, font $transition-duration;
    overflow: hidden;
    color: #757575 !important;

    &:not(:hover):not(.active) img {
      opacity: 0 !important;
    }

    &:hover:not(.active), &:focus:not(.active) {
      background-color: $dark !important;
      color: #d5d5d5 !important;
    }

    &.active {
      flex: 1 1 250%;
      @extend .bg-warning, .shadow-warning, .text-dark-grey, .font-size-24, .font-weight-bold;
    }

    &.text-vertical {
      span {
        transform: rotate(180deg);
        transition: transform $transition-duration;
      }

      &.active {
        span {
          transform: rotate(270deg);
        }
      }
    }

    &:not(.text-vertical) {
      img {
        position: absolute;
        opacity: 0.25;
        transition: opacity $transition-duration, right $transition-duration,
        left $transition-duration, top $transition-duration,
        bottom $transition-duration;
      }

      &:first-of-type {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: calc(60% + 22px), $active1X: calc(32% - 3px), $active1Y: calc(59% - 9px), $type1: 1,
                $init2Y: calc(20% + 9px), $hover2X: 60%, $active2X: 32%, $active2Y: 59%, $type2: 1,
                $init3Y: calc(40% + 18px), $hover3X: 60%, $active3X: calc(30% - 16px), $active3Y: calc(25% + 13px), $type3: 0,
                $init4Y: 40%, $hover4X: calc(60% + 10px), $active4X: calc(30% - 8px), $active4Y: calc(25% - 8px), $type4: 0
        )
      }

      &:nth-of-type(2) {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: calc(75% - 28px), $active1X: 44%, $active1Y: 20%, $type1: 0,
                $init4Y: calc(20% + 2px), $hover4X: 75%, $active4X: 32%, $active4Y: calc(35% + 4px), $type4: 1,
                $init5Y: calc(20% + 10px), $hover5X: 83%, $active5X: calc(32% - 7px), $active5Y: 35%, $type5: 1, $width5: 10,
                $init2Y: 30%, $hover2X: 80%, $active2X: calc(30% - 16px), $active2Y: calc(25% + 13px), $type2: 0, $width2: 20,
                $init3Y: 10%, $hover3X: 95%, $active3X: calc(30% - 20px), $active3Y: calc(25% + 28px), $type3: 1, $width3: 8,
                $init6Y: 10%, $active6X: calc(30%), $active6Y: calc(25% + 8px), $type6: 0, $width6: 14
        )
      }

      &:nth-of-type(3) {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: 62%, $hover1Y: 62%, $active1X: 43%, $active1Y: 20%, $type1: 1,
                $init4Y: calc(20% + 2px), $hover4X: 63%, $active4X: 32%, $active4Y: calc(35% + 4px), $type4: 1,
                $init5Y: calc(20% + 10px), $hover5X: 83%, $active5X: calc(32% - 7px), $active5Y: 35%, $type5: 1, $width5: 10,
                $init2Y: 20%, $hover2X: 70%, $active2X: 20%, $active2Y: calc(45% + 13px), $type2: 0, $width2: 20,
                $init3Y: 10%, $active3X: calc(20% + 20px), $active3Y: calc(45%), $type3: 0, $width3: 8
        )
      }

      &:nth-of-type(4) {
        @include bgAnimation(
                $init1Y: 48%, $hover1X: 63%, $active1X: calc(20% + 23px), $active1Y: calc(39% - 9px), $type1: 1,
                $init2Y: calc(48% - 3px), $hover2X: calc(63% - 22px), $active2X: 38%, $active2Y: 35%, $type2: 1,
                $init3Y: 25%, $hover3X: 57%, $active3X: calc(20% + 16px), $active3Y: calc(25% + 13px), $type3: 0,
                $init4Y: calc(25% + 14px), $hover4X: calc(57% + 10px), $active4X: calc(20% - 8px), $active4Y: calc(25% - 8px), $type4: 0
        )
      }

      &:nth-of-type(5) {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: calc(75% - 28px), $active1X: 44%, $active1Y: 20%, $type1: 1,
                $init4Y: calc(20% + 2px), $hover4X: 75%, $active4X: 32%, $active4Y: calc(35% + 4px), $type4: 1,
                $init5Y: calc(20% + 10px), $hover5X: 83%, $active5X: calc(32% - 7px), $active5Y: 35%, $type5: 1, $width5: 10,
                $init2Y: 30%, $hover2X: 80%, $active2X: calc(30% - 16px), $active2Y: calc(25% + 13px), $type2: 0, $width2: 20,
                $init3Y: 80%, $hover3X: 60%, $active3X: calc(30% - 20px), $active3Y: calc(25% + 28px), $type3: 0, $width3: 8,
                $init6Y: 10%, $active6X: calc(30%), $active6Y: calc(25% + 8px), $type6: 0, $width6: 14
        )
      }
    }

    &.text-vertical {
      &:first-of-type {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: calc(75% - 28px), $active1X: 44%, $active1Y: 60%, $type1: 0,
                $init4Y: calc(60% + 2px), $hover4X: 30%, $active4X: 32%, $active4Y: calc(35% + 4px), $type4: 1,
                $init2Y: 30%, $hover2X: 80%, $active2X: calc(30% - 16px), $active2Y: calc(65% + 13px), $type2: 0, $width2: 20,
                $init3Y: 70%, $hover3X: 95%, $active3X: calc(30% - 20px), $active3Y: calc(25% + 28px), $type3: 1, $width3: 8,
                $init6Y: 10%, $active6X: calc(30%), $active6Y: calc(65% + 8px), $type6: 0, $width6: 14
        )
      }

      &:nth-of-type(2) {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: 65%, $hover1Y: 60%, $active1X: 44%, $active1Y: 20%, $type1: 1,
                $init4Y: 30%, $hover4X: 25%, $active4X: 32%, $active4Y: calc(75% + 4px), $type4: 1,
                $init5Y: calc(30% + 28px), $hover5X: 33%, $active5X: calc(32% - 7px), $active5Y: 75%, $type5: 1, $width5: 10,
                $init2Y: 70%, $hover2X: 80%, $active2X: calc(30% - 16px), $active2Y: calc(25% + 13px), $type2: 0, $width2: 20,
                $init3Y: 10%, $active3X: calc(30% - 20px), $active3Y: calc(25% + 28px), $type3: 0, $width3: 8,
                $init6Y: 10%, $active6X: calc(30%), $active6Y: calc(25% + 8px), $type6: 0, $width6: 14
        )
      }

      &:nth-of-type(3) {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: 58%, $active1X: 32%, $active1Y: 45%, $type1: 0,
                $init2Y: calc(40% + 18px), $hover2X: 52%, $active2X: 28%, $active2Y: 38%, $type2: 1,
                $init3Y: calc(20% + 9px), $hover3X: calc(2% + 20px), $active3X: 52%, $active3Y: 60%, $type3: 0
        )
      }

      &:nth-of-type(4) {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: 58%, $hover1Y: 55%, $active1X: 32%, $active1Y: 15%, $type1: 1,
                $init2Y: calc(40% + 18px), $hover2X: 52%, $hover2Y: 40%, $active2X: 28%, $active2Y: 68%, $type2: 0,
                $init3Y: calc(20% + 9px), $hover3X: calc(2% + 20px), $hover3Y: calc(40% - 8px), $active3X: 52%, $active3Y: 30%, $type3: 1
        )
      }

      &:nth-of-type(5) {
        @include bgAnimation(
                $init1Y: 20%, $hover1X: 62%, $hover1Y: calc(62% - 5px), $active1X: 43%, $active1Y: 20%, $type1: 1,
                $init4Y: calc(20% + 2px), $hover4X: 63%, $hover4Y: 63%, $active4X: 32%, $active4Y: calc(35% + 4px), $type4: 1,
                $init5Y: calc(20% + 10px), $hover5X: 83%, $hover5Y: 83%, $active5X: calc(32% - 7px), $active5Y: 35%, $type5: 1, $width5: 10,
                $init2Y: 20%, $hover2X: 70%, $active2X: 20%, $active2Y: calc(45% + 13px), $type2: 0, $width2: 20,
                $init3Y: 10%, $hover3X: 95%, $active3X: calc(20% + 20px), $active3Y: calc(45%), $type3: 0, $width3: 8
        )
      }
    }

  }
}
