.nav-tabs {
  a {
    overflow: hidden;

    p:first-of-type:before {
      position: absolute;

      @include media-breakpoint-up(sm) {
        left: -16px;
        content: url("./img/caret-white.svg");
      }
    }

    &.active p:first-of-type:before {
      top: -12px;
      left: auto;
      transform: rotate(90deg);
      content: url("./img/caret.svg");
    }

    &.active {
      @extend .rounded, .bg-warning, .shadow-warning, .text-dark-grey;

      &:hover {
        color: #222;
      }
    }
  }

  a:not(.active) {
    @extend .text-body;
  }

  @include media-breakpoint-down(sm) {
    li {
      transition: flex 0.3s, max-width 0.3s;

      &:not(.active) {
        flex-basis: 45%;
        max-width: 45%;
      }

      &.active {
        flex-basis: 55%;
        max-width: 55%;
      }
    }
  }
}
