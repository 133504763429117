// Colors
$body-bg: #222;
$body-color: #D5D5D5;
$warning: #FFDD07;
$dark: #2E2E2E;
$secondary: #757575;
$border-color: $body-bg;

// General
$base-font-size: 16px !default;
$font-family-sans-serif: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// Links
$link-color: $body-color;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 415px,
        md: 768px,
        lg: 1024px,
        xl: 1336px
);

// Modal
$modal-backdrop-bg: #333;
