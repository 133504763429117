.location-tabs {
  @include media-breakpoint-down(sm) {
    .accordion-how-it-works,
    .erreichen,
    .header-right {
      display: none !important;
    }

    .close-route-screen {
      display: inline-block !important;
    }
  }
}
