.route-screen-grid-default {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 5fr 1fr 1fr 1fr;
    gap: 1px 1px;
    grid-template-areas: ". ." "text-1 text-1" ". ." ". ." ". .";
  
    .text-1 {
      grid-area: text-1;
    }
  
    @include media-breakpoint-up(sm) {
      grid-template-columns: 1.1fr 1.1fr 1.1fr 0.7fr;
      grid-template-rows: 1fr 3fr 1fr;
      gap: 1px 1px;
      grid-template-areas: ". . . ." "text-1 text-1 text-1 text-1" ". . . .";
    }
  }
  