@mixin position($type:null, $top:null, $right:null, $bottom:null, $left:null, $zindex:null) {
  @if $type {
    position: $type;
  }
  @if $top {
    top: $top;
  }
  @if $right {
    right: $right;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $left {
    left: $left;
  }
  @if $zindex {
    z-index: $zindex;
  }
}

@mixin bgAnimation(
  $init1X:null, $init1Y:null, $hover1X:null, $hover1Y:null, $active1X:null, $active1Y:null, $width1:null, $type1:null,
  $init2X:null, $init2Y:null, $hover2X:null, $hover2Y:null, $active2X:null, $active2Y:null, $width2:null, $type2:null,
  $init3X:null, $init3Y:null, $hover3X:null, $hover3Y:null, $active3X:null, $active3Y:null, $width3:null, $type3:null,
  $init4X:null, $init4Y:null, $hover4X:null, $hover4Y:null, $active4X:null, $active4Y:null, $width4:null, $type4:null,
  $init5X:null, $init5Y:null, $hover5X:null, $hover5Y:null, $active5X:null, $active5Y:null, $width5:null, $type5:null,
  $init6X:null, $init6Y:null, $hover6X:null, $hover6Y:null, $active6X:null, $active6Y:null, $width6:null, $type6:null
) {
  $types: ("type1": $type1, "type2": $type2, "type3": $type3, "type4": $type4, "type5": $type5, "type6": $type6);
  $widths: ("width1": $width1, "width2": $width2, "width3": $width3, "width4": $width4, "width5": $width5, "width6": $width6);
  $initXs: ("init1X": $init1X, "init2X": $init2X, "init3X": $init3X, "init4X": $init4X, "init5X": $init5X, "init6X": $init6X);
  $initYs: ("init1Y": $init1Y, "init2Y": $init2Y, "init3Y": $init3Y, "init4Y": $init4Y, "init5Y": $init5Y, "init6Y": $init6Y);
  $hoverXs: ("hover1X": $hover1X, "hover2X": $hover2X, "hover3X": $hover3X, "hover4X": $hover4X, "hover5X": $hover5X, "hover6X": $hover6X);
  $hoverYs: ("hover1Y": $hover1Y, "hover2Y": $hover2Y, "hover3Y": $hover3Y, "hover4Y": $hover4Y, "hover5Y": $hover5Y, "hover6Y": $hover6Y);
  $activeXs: ("active1X": $active1X, "active2X": $active2X, "active3X": $active3X, "active4X": $active4X, "active5X": $active5X, "active6X": $active6X);
  $activeYs: ("active1Y": $active1Y, "active2Y": $active2Y, "active3Y": $active3Y, "active4Y": $active4Y, "active5Y": $active5Y, "active6Y": $active6Y);
  $transition-duration: 0.7s;

  img {
    position: absolute;
    opacity: 0.25;
    transition: opacity $transition-duration, right $transition-duration,
    left $transition-duration, top $transition-duration,
    bottom $transition-duration;

    @for $i from 1 through 6 {
      &.rect-#{$i} {
        @if map-get($types, type#{$i}) == 1 {
          right: 100%;
        } @else {
          left: 100%;
        }

        @if map-get($widths, width#{$i}) {
          width: map-get($widths, width#{$i}) + 0px !important;
          height: map-get($widths, width#{$i}) + 0px !important;
        }

        @if map-get($initXs, init#{$i}X) {
          @if map-get($types, type#{$i}) == 1 {
            right: map-get($initXs, init#{$i}X);
          } @else {
            left: map-get($initXs, init#{$i}X);
          }
        }
        @if map-get($initYs, init#{$i}Y) {
          top: map-get($initYs, init#{$i}Y);
        }
      }
    }
  }

  &:hover {
    img {
      @for $i from 1 through 6 {
        &.rect-#{$i} {
          @if map-get($hoverXs, hover#{$i}X) {
            @if map-get($types, type#{$i}) == 1 {
              right: map-get($hoverXs, hover#{$i}X);
            } @else {
              left: map-get($hoverXs, hover#{$i}X);
            }
          }
          @if map-get($hoverYs, hover#{$i}Y) {
            top: map-get($hoverYs, hover#{$i}Y);
          }
        }
      }
    }
  }

  &.active {
    img {
      opacity: 0.5;

      @for $i from 1 through 6 {
        &.rect-#{$i} {
          @if map-get($activeXs, active#{$i}X) {
            @if map-get($types, type#{$i}) == 1 {
              right: map-get($activeXs, active#{$i}X);
            } @else {
              left: map-get($activeXs, active#{$i}X);
            }
          }
          @if map-get($activeYs, active#{$i}Y) {
            top: map-get($activeYs, active#{$i}Y);
          }
        }
      }
    }
  }
}
