// text
.text-zilla-slab {
  font-family: "Zilla Slab", serif;
}

.text-dark-grey {
  color: $body-bg !important;
}

.text-vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.lh-2 {
  line-height: 2;
}

// background
.bg-md-dark {
  @include media-breakpoint-up(md) {
    background-color: $dark !important;
  }
}

// spacers
.ml-2px {
  margin-left: 2px;
}

.mr-2px {
  margin-right: 2px;
}

.ml-md-2px {
  @include media-breakpoint-up(md) {
    margin-left: 2px !important;
  }
}

.mb-2px {
  margin-bottom: 2px;
}

// sizing
.w-60px {
  width: rem-calc(60px);
}

.h-60px {
  height: rem-calc(60px);
}

.h-80px {
  height: rem-calc(80px);
}

// border
.rounded-bottom-right {
  border-bottom-right-radius: $border-radius;
}

.rounded-bottom-left {
  border-bottom-left-radius: $border-radius;
}

.shadow-warning {
  box-shadow: 0 0 4px 0 rgba($warning, 0.5);
}

// flex
.flex-1-1 {
  flex: 1 1 100%;
}

// position
.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.z-index-1 {
  z-index: 1;
}

// overlay
.overlay-gradient:after {
  @include position(absolute, 0, 0, 0, 0, 0);
  content: '';
  background-image: linear-gradient(180deg, rgba(91, 91, 91, 0) 0, rgba(71, 71, 71, 0.2) 80%, rgba(39, 39, 39, 0.7) 100%);
}

// object-fit
.object-fit-contain {
  object-fit: contain;
}

.text-decoration-underline {
  text-decoration: underline;
}
