$fontSizesMin: 10 !default;
$fontSizesMax: 50;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $size from $fontSizesMin through $fontSizesMax {
      .font-size#{$infix}-#{$size} {
        font-size: $size / 16 + 0rem;
      }

      @if $size < 26 {
        $size: $size + 1;
      } @else {
        $size: $size + 2;
      }
    }
  }
}
