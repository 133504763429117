html {
  font-size: $base-font-size;

  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }

  @media (min-width: 1336px) and (max-width: 1699px) {
    font-size: 13px;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-down(sm) {
    padding-left: 2px;
    padding-right: 2px;
  }
}

#root {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}

img {
  object-fit: cover;
}
